@import '../../../styles/colors.scss';
@import '../../../styles/ornaments.scss';
@import '../../../styles/fonts.scss';

.button {
  background-color: transparent;
  color: var( --primary-color-300 );

  @include text-shadow( 'button' );

  @include font( 'button' );
  font-size: 1.2rem;
  letter-spacing: 0.2rem;

  transition: 0.7s color, 0.2s border-bottom;

  &:hover {
    color: var( --primary-color-500 );
  }
}
