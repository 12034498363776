@import '../../styles/layout.scss';
@import '../../styles/fonts.scss';

.page-did-not-load-page {
  @include page();

  overflow: hidden;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .soft-disk:nth-child(1) {
    position: absolute;
    z-index: -1;

    right: -5%;
    top: -60%;

    pointer-events: none;
  }

  .soft-disk:nth-child(2) {
    position: absolute;
    z-index: -1;

    left: -5%;
    top: 100%;

    pointer-events: none;
  }

  main {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding-bottom: 20vh;

    header {
      position: relative;
      font-size: 3em;
      padding-bottom: 0.4em;
      text-align: center;

      @include font( 'heading' );
      color: var( --primary-color );
    }

    div {
      display: flex;
      flex-direction: column;
      justify-items: center;
      align-items: center;

      .paragraph {
        max-width: 45ch;
        text-align: center;
      }

      .glass-card {
        width: 15em;
        padding: 0.4em;
        margin-top: 0.5em;

        display: flex;
        flex-direction: row;
        justify-content: center;

        .button {
          padding: 0.5em;
        }
      }
    }
  }
}