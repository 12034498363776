/* Responsiveness */

// Font size
$grow-start-width: 320px;
$grow-stop-width: 1000px;

$min-font-size: 14px;
$max-font-size: 22px;

$min-heading-multiplier: 1.45; // mobile
$max-heading-multiplier: 2.2; // desktop

// credit: https://css-tricks.com/snippets/css/fluid-typography/
@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {

    & {

      font-size: $min-font-size;

      @media screen and (min-width: $min-vw) {
        font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
      }
      @media screen and (min-width: $max-vw) {
        font-size: $max-font-size;
      }
    }
  }
}

// Device widths
$small-device-width:  400px; 
$medium-device-width: 700px;
$large-device-width:  1150px;
$huge-device-width:   1400px;

@mixin small {
  @media ( min-width: $small-device-width )  {
    @content;
  }
}

@mixin medium {
  @media ( min-width: $medium-device-width )  {
    @content;
  }
}

@mixin large {
  @media ( min-width: $large-device-width )  {
    @content;
  }
}

@mixin huge {
  @media ( min-width: $huge-device-width )  {
    @content;
  }
}

@mixin custom( $device-width ) {
  @media ( min-width: $device-width ) {
    @content;
  }
}



/* Padding */
$page-padding-vertical: 1.5em;
$page-padding-horizontal: 1.0em;

$page-max-width: $huge-device-width;

@mixin padded-dimensions( $padding-horizontal, $padding-vertical ) {
  width: #{ 'calc( 100% - 2 * #{ $padding-horizontal } )' };
  min-height: #{ 'calc( 100vh - 2 * #{ $padding-vertical } )' };
  max-width: 100%;
  padding: $padding-vertical $padding-horizontal;
}

/* Styled mixins */

@mixin page() {
  height: auto;

  @include padded-dimensions( 0.4 * $page-padding-horizontal, 0.8 * $page-padding-vertical );

  @include medium {
    @include padded-dimensions( $page-padding-horizontal, $page-padding-vertical );
  }

  @include large {
    @include padded-dimensions( 1.8 * $page-padding-horizontal, $page-padding-vertical );
  }

  @include huge {
    margin: auto;
    max-width: $page-max-width;
  }

  position: relative;
}