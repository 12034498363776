@import '../../../styles/colors.scss';
@import '../../../styles/ornaments.scss';

.glass-card {
  z-index: 2;
  position: relative;

  box-shadow: 
    0px -1px 1px #E5B5B5,
    0px  1px 1px #6F517141,
    0px  0px 20px #9A5E5E40,

    inset 20px 20px 15px -20px #ddacac60,
    inset -20px -20px 15px -20px #8d4c5c50;
  ;

  border-radius: 20%;

  @include use-glass();
}