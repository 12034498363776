@import '../../../../styles/colors.scss';
@import '../../../../styles/layout.scss';
@import '../../../../styles/ornaments.scss';

$size: 7.0rem;

@mixin soft-disk-shadows( $size ) {
  box-shadow: 
    // Glow
    shadow( $size, -0.026, -0.026, 0.052, rgba(255, 222, 222, 0.13) ),
    shadow( $size, 0.026, 0.026, 0.052, rgba(98, 183, 183, 0.27) ),

    // Main shadows
    shadow( $size, 0.136, 0.136, 0.158, rgba(122, 147, 213, 0.29) ),
    shadow( $size, 0.178, 0.178, 0.104, rgba(120, 19, 19, 0.25) ),

    // Shape shadows
    inset shadow( $size, -0.104, -0.104, 0.208, rgba(66, 59, 151, 0.26) ),
    inset shadow( $size, 0.104, 0.104, 0.208, rgba(208, 206, 181, 0.59) )
  ;
}

@mixin soft-disk-style( $size ) {
  width: $size;
  height: $size;
  border-radius: 100%;

  @include soft-disk-shadows( $size );
}

.soft-disk {
  background-color: color( 'glass' );

  @include soft-disk-style( $size * 1.0 );

  @include huge {
    @include soft-disk-style( $size * 1.1 );
  }
}