@import './styles/colors.scss';
@import './styles/ornaments.scss';

.app {
  @each $theme, $_ in $themed-colors {
    &--#{$theme} {
      @include colorScheme( $theme );
    }
  }
}

.overflow {
  background-color: green;
  width: 100vw;
  height: 100%;
}