@import './colors.scss';

/* Shadows */
@function shadow( $size, $xOffset, $yOffset, $blur, $color ) {
  $x: $size * $xOffset;
  $y: $size * $yOffset;
  $blur: $size * $blur;

  @return $x $y $blur $color;
}

$box-shadows: (
  'simple-soft': 0px 0px 15px #00000022
);

$text-shadows: (
  'title': 0px 0px 10px color( 'text-shadow' ),
  'button': 0px 0px 5px color( 'text-shadow' )
);

@mixin box-shadow( $type ) {
  $parameters: map-get( $box-shadows, $type );

  box-shadow: $parameters;
}

@mixin text-shadow( $type ) {
  $parameters: map-get( $text-shadows, $type );

  text-shadow: $parameters;
}

@mixin neumorphic-shadow( $distance, $intensity, $type, $background-color ) {
  $bright-color: lighten( $background-color, $intensity );
  $dark-color:   darken( $background-color, $intensity );

  @if $type == 'extrude' {

    box-shadow: 
      $distance / 2.0 
      $distance / -2.0 
      $distance 
      $bright-color,

      $distance / -2.0 
      $distance / 2.0 
      $distance 
      $dark-color;

  } @else {

    box-shadow: 
      inset 
      $distance / -2.0 
      $distance / -2.0 
      $distance 
      $bright-color,

      inset 
      $distance / 2.0 
      $distance / 2.0 
      $distance 
      $dark-color

  }
}

/* Glass */
$glass-backdrop-filter: blur( 8px );

@mixin use-glass {
  @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
    background-color: var(--glass-color);
    backdrop-filter: $glass-backdrop-filter;
  }

  @supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    background-color: var(--glass-color);
    backdrop-filter: $glass-backdrop-filter;
  }
}
