@import '../../../styles/colors.scss';
@import '../../../styles/ornaments.scss';

@mixin fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.gradient-background {
  z-index: -1;

  @include fullscreen();

  transition: 1s opacity;
  opacity: 1;

  * {
    opacity: 0;
    transition: 1s opacity;
    @include fullscreen();
  }

  @each $theme-name, $_ in $themed-colors {
    &__#{$theme-name} {
      background-image: gradient( $theme-name );
    }
  }

  &--active {
    opacity: 1;
  }
}