@keyframes fadeIn {
  from {
    opacity: 0;
  } 
  to {
    opacity: 1;
  }
}

.page-wrapper {
  overflow: hidden;

  &--scroll {
    overflow-y: scroll;
    scroll-behavior: smooth;
  }

  animation: fadeIn 0.7s;

  margin: unset;

  transition: 0.5s opacity;
  opacity: 1;
  &--fade-out {
    opacity: 0;
  }
}