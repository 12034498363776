// Colors used for all pages
$general-colors: (
  'foreground': #EFEDDE,

  'glass': #9D919125,
  'glass-fallback': #928994D8,

  'text-shadow': #66666677, // #FF9C9C7F

  'warning': #BB1020
);

// Colors for specific pages / themes
$themed-colors: (

  'horizon': (
    'primary': (
      300: #53FF00,
      400: #73FF00,
      500: #93FF00
    ),

    'secondary': #91949D,
    'accent': #E07D7D,
  ),

  'swamp': (
    'primary': (
      300: #00FFD1,
      400: #10FFE1,
      500: #20FFF1,
    ),
    
    'secondary': #A79A78,
    'accent': #1B674C,
  ),

  'dirty': (
    'primary': (
      300: #E00000,
      400: #E01010,
      500: #E02020,
    ),

    'secondary': #c296e6, 
    'accent': #978F43,
  ),

  'vapor': (
    'primary': (
      300: #FAFF00,
      400: #FFFF20,
      500: #FFFF40,
    ),

    'secondary': #CA7281,
    'accent': #438397
  ),

  'haze': (
    'primary': (
      300: #4000C9,
      400: #5010EE,
      500: #6020FF,
    ),

    'secondary': #72CA9F,
    'accent': #974343
  ),

  'digital': (
    'primary': (
      300: #24FF00,
      400: #44FF20,
      500: #64FF50,
    ),

    'secondary': #FF1414,
    'accent': #2830F0
  )
);

// Gradients
$gradients: (

  // Horizon
  'horizon': linear-gradient(
    180deg, 
    color( 'secondary', 'horizon' ) 0%, 
    color( 'accent', 'horizon' ) 100%
  ),

  // Swamp
  'swamp': linear-gradient(
    180deg, 
    color( 'secondary', 'swamp' ) 0%, 
    color( 'accent', 'swamp' ) 100% 
  ),
  'swamp-blue': linear-gradient(
    180deg, 
    color( 'secondary' ) 0%, 
    rgba(47, 148, 108, 0.1) 100%
  ),

  // Dirty
  'dirty': linear-gradient(
    180deg,
    color( 'secondary', 'dirty' ) 0%,
    color( 'accent', 'dirty' ) 100%,
  ),

  // Vapor
  'vapor': linear-gradient(
    180deg,
    color( 'secondary', 'vapor' ) 0%,
    color( 'accent', 'vapor' ) 100%,
  ),

  // Haze
  'haze': linear-gradient(
    180deg,
    color( 'secondary', 'haze' ) 0%,
    color( 'accent', 'haze' ) 100%,
  ),

  // Haze
  'digital': linear-gradient(
    180deg,
    color( 'secondary', 'digital' ) 0%,
    color( 'accent', 'digital' ) 100%,
  )
);

@function gradient( $type ) {
  $parameters: map-get( $gradients, $type );
  @return $parameters;
}


@function getColorTint( $color-or-map, $tint ) {
  @if type-of( $color-or-map ) == color {

    @return $color-or-map;

  } @else {

    @return map-get( $color-or-map, $tint );

  }
}

@function color( $name, $theme: 'horizon', $tint: 300 ) {
  $color-or-map: map-get( $general-colors, $name );

  @if $color-or-map != null {

    @return getColorTint( $color-or-map, $tint );

  } @else {

    $theme: map-get( $themed-colors, $theme );
    @return getColorTint( map-get( $theme, $name ), $tint );
  }
};

// Converts a color map to css variables
// This allows for a simple color theme switch when using 
// the regular css variables instead of the scss variables
@mixin colorsToCSSVars( $colors, $suffix: 'color' ) {

  @each $name, $color-or-map in $colors {

    @if type-of( $color-or-map ) == color {

      --#{$name}-#{$suffix}: #{$color-or-map};

    } @else {

      @each $tint, $color in $color-or-map {
        @if $tint == 300 {
          --#{$name}-#{$suffix}: #{$color};
        }

        --#{$name}-#{$suffix}-#{$tint}: #{$color};
      }
    }
  }
}

// Use a specific colorscheme
@mixin colorScheme( $theme ) {
  @include colorsToCSSVars( $general-colors );
  @include colorsToCSSVars( map-get( $themed-colors, $theme ) );
}
