@import '../../styles/colors.scss';
@import '../../styles/fonts.scss';

.paragraph {
  line-height: 1.1rem;
  padding-bottom: 0.6em;

  color: color( 'foreground' );
  @include font( 'paragraph' );

  &--normal {
    font-size: 1.1rem;
  }

  &--bold {
    // text-transform: uppercase;
    font-size: 1.2rem;
    line-height: 1.2rem;
  }

  max-width: calc( min( 95vw, 55ch ) );
}