@import '../../styles/layout.scss';
@import '../../styles/fonts.scss';

.not-found-page {
  @include page();

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  &__obstacle {
    position: absolute;

    left: 25%;
    top: 35%;

    width: 400px;

    z-index: 3;

    transform: rotate( -60deg );

    pointer-events: none;
  }

  main {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding-bottom: 20vh;

    header {
      position: relative;
      font-size: 3em;
      padding-bottom: 0.4em;

      @include font( 'heading' );
      color: var( --primary-color );

      max-width: 530px;
      @include medium {
        max-width: 75vw;
      }
    }

    .paragraph {
      width: 100%;
      max-width: 100vw;

      padding-left: 0.4em;
    }

    nav {
      padding-top: 0.6em;

      max-width: 25em;
      width: 100%;

      .glass-card {
        padding: 0.3em;
        display: flex;
        justify-content: space-around;
      }
      .button {
        max-width: 5em;
      }
    }

    
  }
}