// NOTE: Fonts are loaded from public/index.css to allow for preloading

/* Font data */ 
$fonts: (
  'heading': (
    'name': 'QuicksandRegular',
    'fallback': sans-serif,
    'weight': 300,
    'style': italic,
  ),
  'subheading': (
    'name': 'SyneRegular',
    'fallback': sans-serif,
    'weight': 400,
    'style': normal,
  ),

  'paragraph': (
    'name': 'SyneRegular',
    'fallback': sans-serif,
    'weight': 300,
    'style': normal
  ),

  'button': (
    'name': 'SyneMono',
    'fallback': sans-serif,
    'weight': 500,
    'style': normal
  ),

  'accent': (
    'name': 'SyneItalic',
    'fallback': sans-serif,
    'weight': 600,
    'style': normal,
  )
);

/*
  This mixin enables easy font usage.
  By passing the font name, the font-family,
  fallback font and font-weight will automatically be set.

  The default weight can be overridden by passing a weight value. 
*/
@mixin font( $type, $weight : 0 ) {
  $fontData: map-get( $fonts, $type );

  $name:     map-get( $fontData, 'name' );
  $fallback: map-get( $fontData, 'fallback' );
  $style:    map-get( $fontData, 'style' );

  /* Use default weight if no weight is passed */
  @if $weight == 0 {
    $weight: map-get( $fontData, 'weight' );
  }

  font-family: $name, $fallback;
  font-weight: $weight;
  font-style: $style;
}